<template>
  <div class="page-wrapper chiller-theme" style="margin-top: 71px;">
    <main class="page-content">
      <div class="container-fluid">
        <ul class="breadcrumb ProximaNovaRegular" style="margin-top: 12px">
          <li class="ProximaNovaBold">
            Settings
          </li>
          <li class="ProximaNovaBold">
            Reauthorize
          </li>
        </ul>
      </div>
      <div class="container-fluid"></div>
      <div class="container-fluid m-0 p-0 mt-5">
        <div class="m-5 content-center" style="justify-content: space-between;">
          <h2 class="ProximaNovaBold">Reauthorize</h2>
        </div>
        <div class="mx-5 text-right" style="font-size: small;">
          <div>
            {{ rowsPrePage * currentPage - 4 }} -
            {{ rowsPrePage * (currentPage - 1) + tableDataValues.items.length }}
            of {{ totalRows }}
          </div>
        </div>
        <div class="m-5">
          <w-table
            :tableProperties="tableDataValues"
            :noDataText="'No Connection found'"
            :showHeading="true"
            :headingText="'Reauthorize Connection List'"
            :customRowColor="true"
            @reauthorize="reauthorize($event)"
          />
          <div class="d-flex" style="justify-content: end;">
            <w-pagination
              :currentPage="currentPage"
              :perPage="rowsPrePage"
              :totalRows="totalRows"
              @getSelectedPageEvent="getSelectedPageEvent($event)"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import DataTable from "@/widgets/DataTable.vue";
import { DataConnection } from "@/services/DataConnectionService";
import Pagination from "@/widgets/Pagination.vue";
// import Button from "@/components/Profile/Button.vue";
import { ApiHelper } from "@/services/helper/ApiHelper.js";
let apiHelper = new ApiHelper();
const dataConnection = new DataConnection();
export default {
  components: {
    "w-table": DataTable,
    "w-pagination": Pagination,
    // "w-button": Button,
  },
  data() {
    return {
      rowsPrePage: 5,
      totalRows: 0,
      currentPage: 1,
      avilableBrand: [],
      tableDataValues: {
        fields: [
          {
            key: "channel",
            label: "Channel",
            thClass: "thClassLeft",
            tdClass: "text-left",
          },
          {
            key: "connectionName",
            label: "Connection Name",
            thClass: "thClassLeft",
            tdClass: "text-left",
          },
          {
            key: "datastreamName",
            label: "DataStream Name",
            thClass: "thClassLeft",
            tdClass: "text-left",
          },
          {
            key: "Reauthorize",
            label: "",
            thClass: "thClassLeft",
            tdClass: "text-left",
          },
        ],
        items: [],
      },
      addBrandLoader: "none",
    };
  },
  methods: {
    reauthorize(e) {
      dataConnection
        .reauthorize(sessionStorage.getItem("subId"), e.adverityDatastreamId)
        .then((res) => {
          var winobj = window.open(
            res.url,
            "Snopzer",
            "left=500,top=150,width=500,height=500,toolbar=1,resizable=0"
          );
          var self = this;
          var loop = setInterval(
            function() {
              if (winobj.closed) {
                const uri = `health-monitor-module/adverity/refetch?adverityConnectionId=${e.connectionId}&datastreamId=${e.adverityDatastreamId}`;
                apiHelper.get(uri);
                self.getTabledata();
                clearInterval(loop);
              }
            }.bind(this),
            100
          );
        });
    },
    getTabledata() {
      dataConnection
        .reauthorizeConnection(
          sessionStorage.getItem("clientId"),
          sessionStorage.getItem("subId"),
          this.currentPage,
          this.rowsPrePage
        )
        .then((res) => {
          this.tableDataValues.items = [];
          this.tableDataValues.items = res.response.content;
          this.totalRows = res.response.totalElements;
        });
    },
    getSelectedPageEvent(event) {
      if (event != this.currentPage) {
        this.currentPage = event;
        this.getTabledata();
      }
    },
  },
  mounted() {
    this.getTabledata();
  },
};
</script>

<style></style>
